import React from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowCircleLeft from '../assets/images/arrow_circle_left.png';
import ArrowCircleRight from '../assets/images/arrow_circle_right.png';

const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
  '&.Mui-selected': {
    width: 35.29,
    height: 35.29,
    background: 'rgba(255, 255, 255, 0.08)',
    borderRadius: 44.11,
    border: '0.63px rgba(255, 255, 255, 0.17) solid',
    color: 'white',
    fontSize: 17.65,
    fontFamily: 'Arial',
    fontWeight: 400,
    wordWrap: 'break-word',
  },
  '&:not(.Mui-selected)': {
    opacity: 0.4,
    color: 'white',
    fontSize: 17.65,
    fontFamily: 'Arial',
    fontWeight: 400,
    wordWrap: 'break-word',
  },
  '& .MuiPaginationItem-icon': {
    width: 30.25,
    height: 30.25,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  '&.MuiPaginationItem-previous': {
    '& .MuiPaginationItem-icon': {
      backgroundImage: `url(${ArrowCircleLeft}) !important`, // Aplica la imagen de fondo correctamente
    },
  },
  '&.MuiPaginationItem-next': {
    '& .MuiPaginationItem-icon': {
      backgroundImage: `url(${ArrowCircleRight}) !important`, // Aplica la imagen de fondo correctamente
    },
  },
}));

const CustomPagination = (props) => {
  return (
    <Pagination
      {...props}
      renderItem={(item) => (
        <CustomPaginationItem
          {...item}
        />
      )}
    />
  );
};

export default CustomPagination;
