import React, { useState } from "react";
import { makeRequest } from "../../../assets/functions_helper";
import { useNavigate } from "react-router-dom";
import ListRestaurants from "./list_restaurant";

const MainRestaurants = () => {
  const navigate = useNavigate();
  const [restaurants, setRestaurants] = useState([]);
  const [total, setTotal] = useState(0); // Variable de estado para guardar el total de usuarios
  const [pages, setPages] = useState(0); // Variable de estado para guardar el total de paginas
  const [currentPage, setCurrentPage] = useState(0); // Variable de estado para guardar la pagina actual
  const [isLoaded, setIsLoaded] = useState(false); // Variable de estado para saber si la petición ha cargado

  React.useEffect(() => {
    let url = `restaurants/?page=1&size=5&order_by=id&order=desc`;
    
    if (!localStorage.getItem("rol")) {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenType");
      navigate("/login");
      return;
    }else{
        if (localStorage.getItem("rol") === "2") {
            url = `restaurant_admin/list_my_restaurants?page=1&size=5&order_by=id&order=desc`;
        }
    }

    // Hacer la peticion si nada esta vacio
    makeRequest(
      "GET",
      url,
      {},
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        navigate("/login");
      }
    ).then((response) => {
      console.log(response);
      const updateRestaurants = response.restaurants.map((restaurant) => ({
        ...restaurant,
        title: restaurant.name,
        title2: `Usuarios: ${restaurant.user_count}`,
      }));
      setRestaurants(updateRestaurants);
      setTotal(response.total);
      setPages(response.pages);
      setCurrentPage(response.currentPage);
      setIsLoaded(true);
    });
  }, [navigate]);

  return (
    <>
      <ListRestaurants
        restaurants={restaurants}
        setRestaurants={setRestaurants}
        total={total}
        setTotal={setTotal}
        pages={pages}
        setPages={setPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isLoaded={isLoaded}
        setIsLoaded={setIsLoaded}
      />
    </>
  );
};

export default MainRestaurants;
