import React, { useState } from 'react';
import {
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { IconButtonAnimated } from './resources';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

const StyledRowBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: '85%',
  background: 'rgba(0, 0, 0, 0.10)',
  boxShadow: '0px 0px 6px rgba(255, 255, 255, 0.15) inset',
  borderRadius: 15,
  border: '0.50px rgba(255, 255, 255, 0.70) solid',
  backdropFilter: 'blur(60px)',
  '& .MuiSelect-select': {
    background: 'rgba(0, 0, 0, 0.10)',
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.10)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.20)',
  },
}));

const StyledIconButton = styled(IconButtonAnimated)(({ theme }) => ({
  margin: '10px',
  background: 'rgba(0, 0, 0, 0.10)',
  boxShadow: '0px 0px 6px rgba(255, 255, 255, 0.15) inset',
  borderRadius: 15,
  border: '0.50px rgba(255, 255, 255, 0.70) solid',
  backdropFilter: 'blur(60px)',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 10,
  padding: theme.spacing(1),
  margin: theme.spacing(0.5, 0)
}));

const StyledIconButtonDelete = styled(IconButton)(({ theme }) => ({
  color: theme.palette.error.main,
}));

function SelectMoveData({
  items,
  currentItems,
  selectedRoles,
  setSelectedRoles,
}) {
  // Filtrar los elementos de 'items' que no están en 'currentItems'
  const [selectItems, setSelectItems] = useState(
    items.filter(
      (item) => !currentItems.some((currentItem) => currentItem.id === item.id)
    )
  );

  const [selectedItem, setSelectedItem] = useState(
    selectItems.length > 0 ? selectItems[0] : ''
  );
  const [listItems, setListItems] = useState(currentItems);

  const handleSelectChange = (event) => {
    setSelectedItem(event.target.value);
  };

  const handleAddToList = () => {
    if (!selectedItem) {
      // Si selectedItem es undefined o '', simplemente retorna
      return;
    }
    setListItems([...listItems, selectedItem]);
    setSelectedRoles([...listItems, selectedItem]);
    setSelectItems(selectItems.filter((item) => item.id !== selectedItem.id));
    setSelectedItem('');
  };

  const handleRemoveFromList = (itemToRemove) => {
    setListItems(listItems.filter((item) => item !== itemToRemove));
    setSelectItems([...selectItems, itemToRemove]);
  };

  return (
    <StyledBox>
      <StyledRowBox>
        <StyledSelect
          value={selectedItem}
          onChange={handleSelectChange}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: 'rgba(0, 0, 0, 0.10)',
              },
            },
          }}
        >
          {selectItems.map((item, index) => (
            <StyledMenuItem key={index} value={item}>
              {item.title}
            </StyledMenuItem>
          ))}
        </StyledSelect>
        <StyledIconButton
          edge="end"
          aria-label={'Añadir a la lista'}
          onClick={() => handleAddToList()}
        >
          <AddIcon sx={{
            color: 'white',
            fontSize: 20,
            fontFamily: 'Arial',
            fontWeight: '400',
          }}/>
        </StyledIconButton>
      </StyledRowBox>
      <List>
        {listItems.map((item, index) => (
          <StyledListItem key={index}>
            <ListItemText primary={item.title}/>
            <StyledIconButtonDelete
              edge="end"
              aria-label="delete"
              onClick={() => handleRemoveFromList(item)}
            >
              <DeleteIcon />
            </StyledIconButtonDelete>
          </StyledListItem>
        ))}
      </List>
    </StyledBox>
  );
}

export default SelectMoveData;
