import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import { createTheme } from '@mui/material/styles';

export const IconButtonAnimated = styled(IconButton)(() => ({
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.2)',
  },
  color: '#4EACDA',
}));

export const ButtonAnimated = styled(Button)(() => ({
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});