import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material";

// Estilos para el componente CustomSelect
const SelectContainer = styled("div")({
  position: "relative",
});

const CustomSelectDisplay = styled("div")({
  width: 111,
  height: 42,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 10,
  paddingBottom: 10,
  background: "rgba(255, 255, 255, 0.08)",
  boxShadow: "0px 0px 6px rgba(255, 255, 255, 0.05) inset",
  borderRadius: 200,
  border: "0.50px rgba(255, 255, 255, 0.70) solid",
  justifyContent: "center",
  alignItems: "center",
  gap: 4,
  display: "flex",
  cursor: "pointer",
});

const SelectedLabel = styled("div")({
  color: "white",
  fontSize: 12,
  fontFamily: "Arial",
  fontWeight: "400",
  textAlign: "center",
  width: "100%",
});

const OptionsContainer = styled("div")({
  position: "absolute",
  background: "rgba(255, 255, 255, 0.08)",
  boxShadow: "0px 0px 6px rgba(255, 255, 255, 0.05) inset",
  borderRadius: 10,
  border: "0.50px rgba(255, 255, 255, 0.70) solid",
  marginTop: 10,
  zIndex: 1000,
  width: 170, // Ajusta el ancho según tus necesidades
});

const Option = styled("div")({
  color: "white",
  fontSize: 12,
  fontFamily: "Arial",
  fontWeight: "400",
  padding: 10,
  cursor: "pointer",
  textAlign: "center",
  transition: "background 0.3s ease, color 0.3s ease",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.2)", // Color de fondo en hover
    color: "#000", // Color del texto en hover
  },
});

const HiddenSelect = styled("select")({
  display: "none",
});

const CustomSelect = ({ value, onChange, valuesFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const containerRef = useRef(null);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };

  const handleCustomSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    setSelectedValue(value);
    onChange(value);
    setIsOpen(false);
  };

  const selectedLabel = valuesFilter.find(
    (role) => role.id === selectedValue
  )?.title || "Todos";

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SelectContainer ref={containerRef}>
      {/* Custom Select Display */}
      <CustomSelectDisplay onClick={handleCustomSelectClick}>
        <SelectedLabel>{selectedLabel}</SelectedLabel>
      </CustomSelectDisplay>

      {/* Custom Options Display */}
      {isOpen && (
        <OptionsContainer>
          <Option onClick={() => handleOptionClick("")}>Todos</Option>
          {valuesFilter.map((role) => (
            <Option key={role.id} onClick={() => handleOptionClick(role.id)}>
              {role.title}
            </Option>
          ))}
        </OptionsContainer>
      )}

      {/* Hidden native select for accessibility and form integration */}
      <HiddenSelect
        value={selectedValue}
        onChange={handleSelectChange}
        aria-label="Filtrar por rol"
      >
        <option value="">Todos</option>
        {valuesFilter.map((role) => (
          <option key={role.id} value={role.id}>
            {role.title}
          </option>
        ))}
      </HiddenSelect>
    </SelectContainer>
  );
};

export default CustomSelect;
