import React from "react";
import { CustomTextField } from "../../../components/textFields";

function CreateRestaurant({ restaurantName, setRestaurantName }) {
  return (
      <CustomTextField
        autoFocus
        required
        margin="dense"
        id="name"
        name="name"
        label="Nombre"
        type="text"
        fullWidth
        onChange={(e) => setRestaurantName(e.target.value)}
        value={restaurantName}
      />
  );
}

export default CreateRestaurant;
