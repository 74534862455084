import { Box } from '@mui/material';
import { CustomTextField } from '../../../../components/textFields';

function CreateMenu({ name, description, setName, setDescription }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <CustomTextField
        autoFocus
        required
        margin="dense"
        id="name"
        name="name"
        label="Nombre del Menú"
        type="text"
        fullWidth
        onChange={(e) => setName(e.target.value)}
        value={name}
      />

      <CustomTextField
        autoFocus
        required
        margin="dense"
        id="description"
        name="description"
        label="Descripción del Menú"
        type="text"
        fullWidth
        onChange={(e) => setDescription(e.target.value)}
        value={description}
      />
    </Box>
  );
}

export default CreateMenu;
