import React, { useState, useEffect } from "react";

import { Box } from "@mui/material";
import { CustomTextField } from "../../../../components/textFields";
// import { useNavigate } from "react-router-dom";
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../../components/customDialog";
import { CloseButton, SendButton } from "../../../../components/buttons";
import { makeRequest } from "../../../../assets/functions_helper";
import { useNavigate } from "react-router-dom";

function EditProduct({
  name,
  setName,
  description,
  setDescription,
  price,
  setPrice,
  title,
  setTitle,
  title2,
  setTitle2,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 2,
      }}
    >
      <CustomTextField
        required
        margin="dense"
        id="name"
        label="Nombre del Producto"
        type="text"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <CustomTextField
        required
        margin="dense"
        id="description"
        label="Descripción"
        type="text"
        fullWidth
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <CustomTextField
        required
        margin="dense"
        id="price"
        label="Precio"
        type="number"
        fullWidth
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />

      <CustomTextField
        required
        margin="dense"
        id="title"
        label="Título"
        type="text"
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />

      <CustomTextField
        required
        margin="dense"
        id="title2"
        label="Título Secundario"
        type="text"
        fullWidth
        value={title2}
        onChange={(e) => setTitle2(e.target.value)}
      />
    </Box>
  );
}

function EditProductDialog({
  open,
  handleClose,
  productId,
  restaurantId,
  initialData,
  fetchProducts,
}) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [title2, setTitle2] = useState("");

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setDescription(initialData.description);
      setPrice(initialData.price);
      setTitle(initialData.title);
      setTitle2(initialData.title2);
    }
  }, [initialData]);

  const handleSubmitEditProduct = () => {
    const updatedProduct = {
      name,
      description,
      image_url: initialData.image_url,
      restaurant_id: restaurantId,
      price,
    };

    const formData = new FormData();
    formData.append("updated_product", JSON.stringify(updatedProduct));

    makeRequest(
      "PUT",
      `menu_manager/update_product/${productId}`,
      formData,
      "multipart/form-data",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        navigate("/login");
      }
    )
      .then((response) => {
        console.log("Producto actualizado con éxito:", response);
        fetchProducts();
        handleClose();
      })
      .catch((error) => {
        console.error("Error al actualizar el producto:", error);
      });
  };

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <CustomDialogTitle>Editar Producto</CustomDialogTitle>
      <CustomDialogContent>
        <EditProduct
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          price={price}
          setPrice={setPrice}
          title={title}
          setTitle={setTitle}
          title2={title2}
          setTitle2={setTitle2}
        />
      </CustomDialogContent>
      <CustomDialogActions>
        <CloseButton onClick={handleClose}>Cerrar</CloseButton>
        <SendButton onClick={handleSubmitEditProduct}>
          Guardar Cambios
        </SendButton>
      </CustomDialogActions>
    </CustomDialog>
  );
}

export default EditProductDialog;
