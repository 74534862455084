import { Box } from "@mui/material";
import { CustomTextField } from "../../../../components/textFields";

function CreateSection({menu_id, nameSection, setNameSection}) {



  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <CustomTextField
        autoFocus
        required
        margin="dense"
        id="name"
        name="name"
        label="Nombre de la Sección"
        type="text"
        fullWidth
        onChange={(e) => setNameSection(e.target.value)}
        value={nameSection}
      />
    </Box>
  );

}

export default CreateSection;