import { Box, TextField, Typography } from "@mui/material";
import { ButtonAnimated } from "../components/resources";
import { makeRequest } from "../assets/functions_helper";
import { useParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import SnackBar from "../components/snackBar";

function ResetPassword() {
  let { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [formCorrect, setFormCorrect] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);

  const handleCheckPasswords = () => {
    if (password !== confirmPassword) {
      setSnackBarOpen(true);
      setSnackBarMessage("Las Contraseñas no Coinciden");
      return false;
    }

    return true;
  };

  useEffect(() => {
    makeRequest(
      "GET",
      `users/validate_reset_key/${token}`,
      {},
      "application/json",
      null,
      null,
      null,
      null,
      () => {}
    )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        setInvalidToken(true);
        setSnackBarMessage("Petición Inválida");
        setSnackBarOpen(true);
      });
  }, [token]);

  const handleSendNewPassword = () => {
    if (handleCheckPasswords()) {
      makeRequest(
        "POST",
        `users/update_password/${token}`,
        { new_password: password },
        "application/json",
        null,
        null,
        null,
        null,
        () => {
          console.log("Error");
        }
      )
        .then((response) => {
          setSnackBarMessage(
            "Contraseña Actualizada Correctamente, Inicia Sesión con tu Nueva Contraseña"
          );
          setSnackBarOpen(true);
          setFormCorrect(true);
          return;
        })
        .catch((error) => {
          console.log(error);
          setSnackBarMessage("Error no se pudo actualizar la contraseña");
          setSnackBarOpen(true);
          return;
        });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          gap: "20px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
          }}
        >
          {formCorrect
            ? "Contraseña Actualizada Correctamente, Inicia Sesión con tu Nueva Contraseña"
            : invalidToken
            ? "Por Favor Vuelva a Enviar un Nuevo Correo Para el Cambio de Contraseña"
            : "Escribe Tu Nueva Contraseña"}
        </Typography>

        {formCorrect ? (
          <ButtonAnimated
            onClick={() => {
              window.location.href = "/login";
            }}
            variant="contained"
            sx={{
              backgroundColor: "#4EACDA",
              color: "white",
              "&:hover": {
                backgroundColor: "#4EACDA",
              },
            }}
            startIcon={<SendIcon />}
          >
            Ingresar
          </ButtonAnimated>
        ) : null}

        {!formCorrect && !invalidToken ? (
          <>
            <TextField
              id="outlined-basic"
              label="Contraseña Nueva"
              variant="outlined"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              sx={{
                width: "300px",
              }}
            />
            <TextField
              id="outlined-basic"
              label="Confirma Tu Contraseña Nueva"
              variant="outlined"
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              sx={{
                width: "300px",
              }}
            />
            <ButtonAnimated
              onClick={handleSendNewPassword}
              variant="contained"
              sx={{
                backgroundColor: "#4EACDA",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4EACDA",
                },
                width: "200px",
              }}
              startIcon={<SendIcon />}
            >
              Enviar
            </ButtonAnimated>
          </>
        ) : null}
      </Box>
      {snackBarOpen ? (
        <SnackBar
          openSnackBar={snackBarOpen}
          setOpenSnackBar={setSnackBarOpen}
          messageSnackBar={snackBarMessage}
        />
      ) : null}
    </>
  );
}

export default ResetPassword;
