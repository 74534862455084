import { useEffect, useState } from "react";
import { makeRequest } from "../../../assets/functions_helper";
import {
  Box,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import SelectMoveData from "../../../components/selectMoveData";
import { useNavigate } from "react-router-dom";
import { CustomSwitch, CustomTextField } from "../../../components/textFields";

function DetailUser({
  userId,
  user,
  setUser,
  roles,
  setRoles,
  selectedRoles,
  setSelectedRoles,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    makeRequest(
      "GET",
      "users/" + userId,
      null,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        navigate("/login");
      }
    )
      .then((response) => {
        const currentUpdatedResponse = response.roles.map((item) => ({
          ...item,
          title: item.name_es,
        }));

        setUser({
          name: response.name,
          last_name: response.last_name,
          email: response.email,
          lastLogin: response.last_login,
          is_active: response.is_active,
          roles: currentUpdatedResponse,
        });

        makeRequest(
          "GET",
          "users/roles",
          null,
          "application/json",
          localStorage.getItem("token"),
          localStorage.getItem("tokenType"),
          null,
          null,
          () => {
            navigate("/login");
          }
        )
          .then((response) => {
            const updatedResponse = response.map((item) => ({
              ...item,
              title: item.name_es,
            }));
            console.log(updatedResponse);

            setRoles(updatedResponse);

            setIsLoaded(true);
          })
          .catch((error) => {
            console.log("Error al obtener los roles");
          });
      })
      .catch((error) => {
        console.log("Error al obtener el usuario");
      });
  }, [userId, setRoles, setUser, navigate]);

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleSwitchChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      {isLoaded ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <CustomTextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Nombre"
            type="text"
            fullWidth
            value={user.name}
            onChange={handleChange}
          />

          <CustomTextField
            autoFocus
            required
            margin="dense"
            id="lastName"
            name="lastName"
            label="Apellido"
            type="text"
            fullWidth
            value={user.last_name}
            onChange={handleChange}
          />

          <CustomTextField
            autoFocus
            required
            margin="dense"
            id="correo"
            name="email"
            label="Correo"
            type="email"
            fullWidth
            value={user.email}
            onChange={handleChange}
          />

          <FormControlLabel
            control={
              <CustomSwitch
                checked={user.is_active}
                name="is_active"
                onChange={handleSwitchChange}
              />
            }
            label="Activo"
          />
          <h3>Roles</h3>
          <SelectMoveData
            items={roles}
            currentItems={user.roles}
            selectedRoles={selectedRoles}
            setSelectedRoles={setSelectedRoles}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default DetailUser;
