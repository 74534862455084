import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { Select } from "@mui/material";

export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    background: "rgba(0, 0, 0, 0.15)",
    borderRadius: 15,
    border: "1px rgba(255, 255, 255, 0.20) solid",
    backdropFilter: "blur(60px)",
    padding: theme.spacing(0.1),
  },
  "& .MuiInputBase-input": {
    color: theme.palette.text.primary,
  },
  "& .MuiFormLabel-root": {
    color: theme.palette.text.secondary,
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
}));

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "transparent",
    '& + .MuiSwitch-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.10)',
      boxShadow: '0px 0px 6px rgba(255, 255, 255, 0.15) inset',
      borderRadius: 15,
      border: '0.50px rgba(255, 255, 255, 0.70) solid',
      backdropFilter: 'blur(60px)',
    },
    "& .MuiSwitch-thumb": {
      background: "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 20,
    height: 20,
    backgroundColor: "transparent",
    borderRadius: 23,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
  },
}));

// Estilización personalizada para el Select
export const CustomSelect = styled(Select)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.15)',
  borderRadius: 15,
  border: '1px rgba(255, 255, 255, 0.20) solid',
  backdropFilter: 'blur(60px)',
  '& .MuiSelect-select': {
    padding: '20px 14px', // Espaciado interno para el texto
    fontSize: '16px', // Tamaño de la fuente
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none', // Eliminamos el borde predeterminado
  },
  '&:focus': {
    outline: 'none',
    boxShadow: '0 0 0 3px rgba(255, 255, 255, 0.5)',
  },
}));