import { Snackbar } from "@mui/material";
import { IconButtonAnimated } from "./resources";
import CloseIcon from "@mui/icons-material/Close";

const SnackBar = ({openSnackBar, setOpenSnackBar, messageSnackBar}) => {

    const action = (
        <>
          <IconButtonAnimated
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {
              setOpenSnackBar(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButtonAnimated>
        </>
      );

    return (
        <Snackbar
            open={openSnackBar}
            autoHideDuration={2000}
            handleClose={() => {
              setOpenSnackBar(false);
            }}
            message={messageSnackBar}
            action={action}
            sx={
                {
                    backgroundColor: "#4EACDA",
                }
            }
          />
    );
}

export default SnackBar;