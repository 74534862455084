import React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import { Box, Typography } from "@mui/material";
import "../../../../assets/styles/products_menu_build_styles.css";

function ProductsMenuBuild({ items, setHasChanges, setItemsWithCorrectOrderValue }) {
  const [currentItems, setCurrentItems] = React.useState([]);

  // Efecto para actualizar currentItems cada vez que items cambia
  React.useEffect(() => {
    const sortedItems = [...items].sort((a, b) => a.order - b.order);
    setCurrentItems(sortedItems);
  }, [items]); // Dependencia en items

  const onSortEnd = (oldIndex, newIndex) => {
    const newItems = arrayMove(currentItems, oldIndex, newIndex);

    const updatedItems = newItems.map((item, index) => ({
      ...item,
      order: index + 1, // Actualizamos la propiedad 'order'
    }));

    setCurrentItems(updatedItems); // Actualiza currentItems con el nuevo orden
    setItemsWithCorrectOrderValue(updatedItems)
    setHasChanges(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "20px", // Puedes ajustar el padding
      }}
    >
      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
      >
        {currentItems.map((item, index) => (
          <SortableItem key={index}>
            <Box
              className="item"
              onClick={() => {}}
              style={{
                backgroundImage: `url(${item.image_url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "white",
                width: "250px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    transition: "background 0.3s ease",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    position: "relative",
                    zIndex: 1,
                    padding: "5px",
                    borderRadius: "4px",
                  }}
                >
                  {item.name} <br />
                  {item.price}
                </Typography>
              </Box>
            </Box>
          </SortableItem>
        ))}
      </SortableList>
    </Box>
  );
}

export default ProductsMenuBuild;
