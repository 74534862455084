import axios from 'axios';
import qs from 'qs'; // Importa la librería qs
const url = 'https://eatery-z81ocn81.b4a.run/'; // URL of the backend production server
//const url = 'http://localhost:3000/'; // URL of the backend development server

export const errorMapping = {
  "0001": "La contraseña no puede ser la misma que la antigua.",
  "0002": "El correo electrónico ya está registrado.",
  "0003": "El nombre del restaurante ya ha sido registrado.",
  "0004": "El nombre de la mesa ya ha sido registrado.",
};

export async function makeRequest(
  method,
  endpoint,
  body = null,
  contentType = 'application/json',
  token = null,
  tokenType = null,
  onTokenExpired = null
) {
  let data = body;

  if (contentType === 'application/x-www-form-urlencoded') {
    data = qs.stringify(body); // Convierte el objeto body a una cadena URL-encoded
  }

  let config = {
    method: method,
    url: url + endpoint,
    data: data,
    headers: {
      'Content-Type': contentType,
    },
  };

  if (token && tokenType) {
    config = {
      method: method,
      url: url + endpoint,
      data: data,
      headers: {
        'Content-Type': contentType,
        Authorization: tokenType + ' ' + token,
      },
    };
  }

  try {
    const response = await axios(config);
    if (response.status >= 400) {
      throw new Error(`Error: ${response.status}`);
    } else if (response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenType');
      if (onTokenExpired) {
        
        //Refresh token
        const refreshToken = await axios.post(url + 'users/refresh_token', {
          token: localStorage.getItem('token'),
        });

        if (refreshToken.status === 200) {
          localStorage.setItem('token', refreshToken.data.access_token);
          localStorage.setItem('tokenType', refreshToken.data.token_type);
        } else {
          onTokenExpired();
        }
      }
    }
    return response.data;
  } catch (error) {
    console.error(error);
    throw error.response.data.detail;
  }
}
