import React, { useState, useEffect, useCallback } from "react";
import { Box, CircularProgress, Snackbar } from "@mui/material";
import { makeRequest } from "../../../../assets/functions_helper";
import { useNavigate } from "react-router-dom";
import ListData from "../../../../components/listData";
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../../components/customDialog";
import CreateProduct from "./create_product";
import { CloseButton, SendButton } from "../../../../components/buttons";
import EditProductDialog from "./edit_product";
import { IconButtonAnimated } from "../../../../components/resources";
import CloseIcon from "@mui/icons-material/Close";

function ListProducts({ restaurantId }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(1);

  const [open, setOpen] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);

  const [productId, setProductId] = useState("");
  const [initialData, setInitialData] = useState({});

  const [messageSnackBar, setMessageSnackBar] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const navigate = useNavigate();

  const fetchProducts = useCallback(() => {
    makeRequest(
      "GET",
      `menu_manager/list_restaurant_products?restaurant_id=${restaurantId}&page=${page}&size=10`,
      {},
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        navigate("/login");
      }
    )
      .then((response) => {
        const updatedProducts = response.products.map((product) => ({
          ...product,
          title: product.name,
          title2: `${product.price}`,
        }));

        setProducts(updatedProducts);
        setTotal(response.total);
        setPages(response.pages);
        setCurrentPage(response.currentPage);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [restaurantId, page, navigate]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleClose = () => {
    setOpen(!open);
    refreshStates();
  };

  const refreshStates = () => {
    setName("");
    setCategory("");
    setDescription("");
    setPrice("");
    setImage(null);
  };

  const handleSubmitCreateProduct = () => {
    if (!name || !category || !price) {
      setMessageSnackBar("Por favor completa todos los campos obligatorios.");
      setOpenSnackBar(true);
      return;
    }

    const formData = new FormData();
    formData.append(
      "new_product",
      JSON.stringify({ name, category, description, price })
    );
    formData.append("product_image", image);
    formData.append("restaurant_id", restaurantId);

    makeRequest(
      "POST",
      `menu_manager/create_product/`,
      formData,
      "multipart/form-data",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        navigate("/login");
      }
    )
      .then((response) => {
        setOpen(false);
        setMessageSnackBar("Producto creado correctamente");
        setOpenSnackBar(true);
        fetchProducts();

        refreshStates();
      })
      .catch((error) => {
        console.error("Error al crear el producto:", error);
      });
  };

  const handleOpenEdit = (id, item) => {
    setProductId(id);
    setInitialData(item);
    setOpenDialogEdit(true);
  };

  const renderSnackbar = () => (
    <Snackbar
      open={openSnackBar}
      autoHideDuration={2000}
      onClose={() => setOpenSnackBar(false)}
      message={messageSnackBar}
      action={
        <IconButtonAnimated
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpenSnackBar(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButtonAnimated>
      }
    />
  );

  const renderCreateProductDialog = () => (
    <CustomDialog open={open} onClose={handleClose}>
      <CustomDialogTitle>Crear Producto</CustomDialogTitle>
      <CustomDialogContent>
        <CreateProduct
          name={name}
          setName={setName}
          category={category}
          setCategory={setCategory}
          description={description}
          setDescription={setDescription}
          price={price}
          setPrice={setPrice}
          setImage={setImage}
        />
      </CustomDialogContent>
      <CustomDialogActions>
        <CloseButton onClick={handleClose}>Cerrar</CloseButton>
        <SendButton onClick={handleSubmitCreateProduct}>Enviar</SendButton>
      </CustomDialogActions>
    </CustomDialog>
  );

  return (
    <>
      {isLoaded ? (
        <>
          <ListData
            title="Productos"
            data={products}
            setData={setProducts}
            pages={pages}
            total={total}
            edit={true}
            editMessage=""
            editFunction={(id, title, public_id, item) => {
              handleOpenEdit(id, item);
            }}
            currentPage={currentPage}
            page={page}
            setPage={setPage}
            changePage={() => {}}
            create={true}
            createMessage="Crear Producto"
            createFunction={() => setOpen(true)}
            nameColumn1="Nombre"
            nameColumn2="Precio"
            nameColumn3="Acciones"
            filterSelect={false}
            filterSelectFunction={(value) => {}}
            valuesFilter={[]}
            valueFilter={""}
          />

          {renderCreateProductDialog()}

          <EditProductDialog
            open={openDialogEdit}
            handleClose={() => {
              setOpenDialogEdit(!openDialogEdit);
              refreshStates();
            }}
            productId={productId}
            restaurantId={restaurantId}
            initialData={initialData}
            fetchProducts={fetchProducts}
          />

          {renderSnackbar()}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default ListProducts;
