import React, { useState, useEffect } from "react";
import { makeRequest } from "../../../assets/functions_helper";
import {
  Box,
  CircularProgress,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { CloseButton, SendButton } from "../../../components/buttons";
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/customDialog";
import SectionsMenuBuild from "./menu_components/sections_menu_build";
import CreateMenu from "./menu_components/create_menu";
import CreateSection from "./menu_components/create_section";
import AddIcon from "@mui/icons-material/Add";
import menuIcon from "../../../assets/images/icons/menu.png";
import ProductsMenuBuild from "./menu_components/products_menu_build";
import { IconButtonAnimated } from "../../../components/resources";
import ArrowCircleLeft from "../../../assets/images/arrow_circle_left.png";
import ProductSelectionDialog from "./menu_components/product_selection";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";

const MenuList = ({ listOfMenus, showMenu }) => (
  <Box display="flex" flexWrap="wrap" justifyContent="center" gap={4}>
    {listOfMenus.map((menu, index) => (
      <Card
        key={index}
        sx={{
          width: 250,
          padding: "20px",
          borderRadius: "25px",
          "&:hover": {
            cursor: "pointer",
            background: "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)",
            transform: "scale(1.05)",
            transition: "background 0.5s ease, transform 0.5s ease",
          },
        }}
        onClick={() => showMenu(menu.id)}
      >
        <CardMedia image={menuIcon} sx={{ height: 300 }} />
        <CardContent>
          <Typography gutterBottom variant="h5">
            {menu.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {menu.description}
          </Typography>
        </CardContent>
      </Card>
    ))}
  </Box>
);

const ButtonBack = ({ page, hasChanges, onBackConfirm }) => (
  <IconButtonAnimated
    size="small"
    aria-label="close"
    color="inherit"
    onClick={() => {
      if (hasChanges) {
        onBackConfirm();
      } else {
        page(false);
      }
    }}
  >
    <img
      src={ArrowCircleLeft}
      alt="Notification"
      style={{ width: "30px", height: "30px" }}
    />
  </IconButtonAnimated>
);

function MenuRestaurant({ restaurantId }) {
  // Estado general del componente
  const [listOfMenus, setListOfMenus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openMenuDialog, setOpenMenuDialog] = useState(false);
  const [openSectionDialog, setOpenSectionDialog] = useState(false);
  const [openProductDialog, setOpenProductDialog] = useState(false);
  // Estados relacionados con la creación de menús y secciones
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameSection, setNameSection] = useState("");

  const [sectionMenu, setSectionMenu] = useState(false);
  const [sectionProducts, setSectionProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [loadingSectionMenu, setLoadingSectionMenu] = useState(false);
  const [sections, setSections] = useState([]);
  const [menuId, setMenuId] = useState(0);
  const [hasChanges, setHasChanges] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [menuInfoComplete, setMenuInfoComplete] = useState(false);
  const [section, setSection] = useState({});

  const [nameOfMenu, setNameOfMenu] = useState("");
  const [itemsWithCorrectOrderValue, setItemsWithCorrectOrderValue] = useState(
    []
  );

  useEffect(() => {
    makeRequest(
      "GET",
      "menu_manager/view",
      null,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        console.log(response);
        setListOfMenus(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error al obtener los menús:", error);
        setLoading(false);
      });
  }, []);

  const createMenu = () => {
    setLoading(true);
    makeRequest(
      "POST",
      "menu_manager/create_menu",
      { restaurant_id: restaurantId, name, description },
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        setListOfMenus([...listOfMenus, response]);
        setLoading(false);
        setOpenMenuDialog(false);
      })
      .catch((error) => {
        console.log("Error al registrar el menú:", error);
        setLoading(false);
      });
  };

  const showMenu = (menuId) => {
    setLoadingSectionMenu(true);
    makeRequest(
      "GET",
      `menu_manager/view/${menuId}`,
      {},
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        console.log(response);
        setMenuInfoComplete(response);
        setSections(response.sections || []);
        setMenuId(menuId);
        setSectionMenu(true);
        setLoadingSectionMenu(false);
        setNameOfMenu(response.name);
      })
      .catch((error) => {
        console.log("Error al obtener las secciones del menú:", error);
        setLoadingSectionMenu(false);
      });
  };

  const createSection = (name) => {
    const sectionCreate = [
      ...sections,
      { name, order: sections.length + 1, products: [] },
    ];
    const menuCompleted = {
      sections: sectionCreate,
      is_active: true,
      restaurant_id: restaurantId,
      menu_id: menuId,
      schedule: {
        days: ["Friday"],
        start_time: "22:00",
        end_time: "23:00",
        timezone: "America/Bogota",
      },
    };
    console.log(menuCompleted);
    setMenuInfoComplete(menuCompleted);
    makeRequest(
      "POST",
      "menu_manager/edit_menu",
      menuCompleted,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        setSections(sectionCreate);

        setOpenSectionDialog(false);
      })
      .catch((error) => {
        console.log("Error al crear la sección:", error);
      });
  };

  const showProducts = (section) => {
    console.log("Mostrar productos de la sección:", section);

    if ("products" in section) {
      setProducts(section.products);
    } else {
      setProducts([]);
    }
    setSection(section);
    setSectionProducts(true);
  };

  const addProduct = (selectedProduct) => {
    const newProduct = {
      description: selectedProduct.description || "Descripción no disponible",
      product_id: selectedProduct.id,
      image_url: selectedProduct.image_url,
      price: selectedProduct.price || 0,
      order: products.length,
      pos_id: selectedProduct.pos_id || "sin_pos_id",
      name: selectedProduct.name || "Producto sin nombre",
    };

    const productsUpdated = [...products, newProduct];

    setProducts(productsUpdated);
    setHasChanges(true);
    setItemsWithCorrectOrderValue(productsUpdated);
    console.log(productsUpdated);
  };

  const saveChanges = (isSections) => {
    let updatedMenuInfo = {};
    let updatedSections = [];
    if (isSections) {
      updatedSections = itemsWithCorrectOrderValue;
    } else {
      console.log(menuInfoComplete);
      updatedSections = menuInfoComplete.sections.map((sec) => {
        if (sec.order === section.order) {
          return {
            ...sec,
            products: itemsWithCorrectOrderValue,
          };
        }
        return sec;
      });
    }

    updatedMenuInfo = {
      ...menuInfoComplete,
      sections: updatedSections,
      menu_id: menuInfoComplete.id ? menuInfoComplete.id : menuInfoComplete.menu_id,
      schedule: {
        days: ["Friday"],
        start_time: "22:00",
        end_time: "23:00",
        timezone: "America/Bogota",
      },
    };

    console.log(updatedMenuInfo);

    makeRequest(
      "POST",
      "menu_manager/edit_menu",
      updatedMenuInfo,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        console.log("Sección guardada exitosamente:", response);
        setSections(updatedSections);
        setHasChanges(false);
      })
      .catch((error) => {
        console.error("Error al guardar la sección:", error);
      });
  };

  const handleBackButton = () => {
    if (hasChanges) {
      setOpenWarningDialog(true);
    } else {
      setSectionProducts(false);
    }
  };

  const handleWarningClose = (save) => {
    setOpenWarningDialog(false);

    if (save) {
      saveChanges();
    } else {
      setSectionProducts(false);
      setHasChanges(false);
    }
  };

  return (
    <>
      {sectionProducts ? ( //PRODUCTS LIST
        <>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              padding: "20px",
            }}
          >
            <ButtonBack
              page={setSectionProducts}
              hasChanges={hasChanges}
              onBackConfirm={handleBackButton}
            />
            <SendButton
              onClick={() => setOpenProductDialog(true)}
              sx={{ maxWidth: "200px" }}
            >
              Agregar Producto
            </SendButton>
            {hasChanges && ( // Condicional para mostrar el botón de guardar
              <SendButton
                onClick={() => saveChanges(false)}
                sx={{ maxWidth: "200px" }}
              >
                Guardar Cambios
              </SendButton>
            )}
          </Box>
          <ProductsMenuBuild
            items={products}
            onClickProducts={() => {}}
            setHasChanges={setHasChanges}
            setItemsWithCorrectOrderValue={setItemsWithCorrectOrderValue}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 4,
              width: "100%",
            }}
          >
            <IconButton
              onClick={() => setOpenProductDialog(true)}
              sx={{
                clipPath: "circle(50% at 50% 50%)",
                background: "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)",
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
          {/* Agregar nuevo producto a la seccion*/}
          <ProductSelectionDialog
            open={openProductDialog}
            handleClose={() => setOpenProductDialog(false)}
            restaurantId={restaurantId}
            onAddProduct={addProduct}
          />
        </>
      ) : sectionMenu ? ( //SECTIONS LIST
        <>
          {loadingSectionMenu ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  padding: "20px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <RestaurantMenuIcon sx={{ color: "white", fontSize: 28 }} />{" "}
                  {/* Icono al inicio */}
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: 24,
                      fontWeight: "400",
                      wordWrap: "break-word",
                      width: "70%",
                      "@media (max-width:950px)": {
                        width: "100%",
                        textAlign: "center",
                      },
                    }}
                  >
                    Secciones del menú {nameOfMenu}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  padding: "20px",
                }}
              >
                <ButtonBack page={setSectionMenu} />
                <SendButton
                  onClick={() => setOpenSectionDialog(true)}
                  sx={{ maxWidth: "200px" }}
                >
                  Crear Nueva Sección
                </SendButton>
                {hasChanges && (
                  <SendButton
                    onClick={() => saveChanges(true)}
                    sx={{ maxWidth: "200px" }}
                  >
                    Guardar Cambios
                  </SendButton>
                )}
              </Box>
              <SectionsMenuBuild
                items={sections}
                onClickProducts={(id) => {
                  showProducts(id);
                }}
                setHasChanges={setHasChanges}
                setItemsWithCorrectOrderValue={setItemsWithCorrectOrderValue}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 4,
                  width: "100%",
                }}
              >
                <IconButton
                  onClick={() => setOpenSectionDialog(true)}
                  sx={{
                    clipPath: "circle(50% at 50% 50%)",
                    background:
                      "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)",
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </>
          )}
        </>
      ) : (
        //MENUS LIST
        <>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                padding: "20px",
                flexDirection: "column",
              }}
            >
              <SendButton
                onClick={() => setOpenMenuDialog(true)}
                sx={{ maxWidth: "200px" }}
              >
                Crear Nuevo Menú
              </SendButton>
              <MenuList listOfMenus={listOfMenus} showMenu={showMenu} />
            </Box>
          )}
        </>
      )}

      {/* Dialogo para crear un nuevo menú */}
      <CustomDialog
        open={openMenuDialog}
        onClose={() => setOpenMenuDialog(false)}
      >
        <CustomDialogTitle>Crear Menú</CustomDialogTitle>
        <CustomDialogContent>
          <CreateMenu
            name={name}
            description={description}
            setName={setName}
            setDescription={setDescription}
          />
        </CustomDialogContent>
        <CustomDialogActions>
          <CloseButton onClick={() => setOpenMenuDialog(false)}>
            Cerrar
          </CloseButton>
          <SendButton onClick={createMenu}>Crear</SendButton>
        </CustomDialogActions>
      </CustomDialog>

      {/* Dialogo para crear una nueva sección */}
      <CustomDialog
        open={openSectionDialog}
        onClose={() => setOpenSectionDialog(false)}
      >
        <CustomDialogTitle>Crear Nueva Sección</CustomDialogTitle>
        <CustomDialogContent>
          <CreateSection
            nameSection={nameSection}
            setNameSection={setNameSection}
          />
        </CustomDialogContent>
        <CustomDialogActions>
          <CloseButton onClick={() => setOpenSectionDialog(false)}>
            Cerrar
          </CloseButton>
          <SendButton onClick={() => createSection(nameSection)}>
            Crear
          </SendButton>
        </CustomDialogActions>
      </CustomDialog>
      {/* Diálogo de advertencia cuando hay cambios no guardados */}
      <CustomDialog
        open={openWarningDialog}
        onClose={() => handleWarningClose(false)}
      >
        <CustomDialogTitle>Advertencia</CustomDialogTitle>
        <CustomDialogContent>
          <Typography variant="body1">
            Tienes cambios no guardados. ¿Deseas guardar antes de volver?
          </Typography>
        </CustomDialogContent>
        <CustomDialogActions>
          <SendButton onClick={() => handleWarningClose(true)}>
            Guardar
          </SendButton>
          <CloseButton onClick={() => handleWarningClose(false)}>
            No guardar
          </CloseButton>
        </CustomDialogActions>
      </CustomDialog>
    </>
  );
}

export default MenuRestaurant;
