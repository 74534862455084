import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgba(255, 255, 255, 0.01)",
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    borderRadius: 40,
    boxShadow: theme.shadows[5],
  },
  backdropFilter: "blur(5px)",
}));

export const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: theme.typography.h6.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  textAlign: "center",
}));

export const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
}));

export const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));
