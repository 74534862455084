import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

// Estilo personalizado para los tabs
const CustomTab = styled(Tab)(({ theme }) => ({
  width: 158,
  height: 52,
  background: '#00001C', // Fondo de las pestañas no seleccionadas
  borderRadius: 7,
  textAlign: 'center',
  color: 'white',
  fontSize: 15,
  fontWeight: 400,
  wordWrap: 'break-word',
  opacity: 0.5, // Texto opaco para pestañas no seleccionadas
  '&.Mui-selected': {
    background: 'linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)', // Fondo para pestañas seleccionadas
    opacity: 1, // Texto completamente opaco para pestañas seleccionadas
    color: 'white',
    border: '1px rgba(255, 255, 255, 1) solid'
  },
  
}));

// Estilo personalizado para Tabs
const CustomTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none', // Ocultar el indicador de selección
  },
  '& .MuiTab-root': {
    minHeight: 'unset', // Quitar altura mínima para evitar el borde
    padding: '0', // Quitar padding predeterminado
  },
}));

// Componente DynamicTabs
const DynamicTabs = ({ tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <CustomTabs value={value} onChange={handleChange} aria-label="dynamic tabs">
        {tabs.map((tab, index) => (
          <CustomTab key={index} label={tab.label} />
        ))}
      </CustomTabs>
      {tabs[value]?.content}
    </div>
  );
};

export default DynamicTabs;
