import React, { useState } from "react";
import { makeRequest } from "../assets/functions_helper";
import { Alert, AlertTitle, Box } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { CustomTextField } from "../components/textFields";
import { SendButton } from "../components/buttons";
import TextLogo from "../assets/images/text-logo-calls.png";
import LogoLogo from "../assets/images/logo-calls.png";

function Login({ setIsLoggedIn }) {
  // Variables de estado para guardar los valores de los inputs
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    // Validar campos vacios
    if (email === "" || password === "") {
      console.log("Campos vacios");
      return;
    } else {
      // Hacer la peticion si nada esta vacio
      makeRequest(
        "POST",
        "users/login",
        {
          username: email,
          password: password,
        },
        "application/x-www-form-urlencoded",
        null,
        null,
        () => {
          navigate("/login");
        }
      )
        .then((response) => {
          localStorage.setItem("token", response.access_token);
          localStorage.setItem("tokenType", response.token_type);
          const userRoles = response.user.roles;
          const userId = response.user.id;

          let minRoleId = 0;
          if (userRoles.length > 0) {
            minRoleId = userRoles.reduce(
              (minId, currentRole) => Math.min(minId, currentRole.id),
              userRoles[0].id
            );
          }

          localStorage.setItem("rol", minRoleId);
          localStorage.setItem("userId", userId);

          setIsLoggedIn(true);
          navigate("/");
        })
        .catch((error) => {
          console.error(error);

          if (error.toString().includes("401")) {
            setAlert(true);
            setAlertMessage("Usuario o contraseña incorrectos");
          } else if (error.toString().includes("500")) {
            setAlert(true);
            setAlertMessage("Error en el servidor, intente más tarde");
          } else {
            setAlert(true);
            setAlertMessage("Error desconocido");
          }
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          padding: "40px",
          borderRadius: "20px",
          boxShadow: "0px 0px 10px 0px #4EACDA",
          backgroundColor: "#070724",
          backdropFilter: "blur(5px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={LogoLogo}
            alt="Logo"
            style={{ width: "75.79", height: "72.39" }}
          />
          <img
            src={TextLogo}
            alt="Logo"
            style={{ width: "75.79", height: "72.39" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "250px",
          }}
        >
          <CustomTextField
            id="outlined-basic"
            label="Email"
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <CustomTextField
            id="outlined-basic"
            label="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            sx={{
              marginBottom: "20px",
            }}
          />

          <SendButton
            onClick={handleSubmit}
            variant="contained"
            sx={{
              backgroundColor: "#4EACDA",
              color: "white",
              "&:hover": {
                backgroundColor: "#4EACDA",
              },
            }}
            startIcon={<SendIcon />}
          >
            Ingresar
          </SendButton>
        </Box>
        <a
          href="/forgotPassword"
          target="_blank"
          style={{
            color: "#4EACDA",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          ¿Olvidaste tu contraseña?
        </a>
        {alert ? (
          <Alert
            severity="error"
            onClose={() => {
              setAlert(false);
            }}
            sx={{ width: "100%" }}
          >
            <AlertTitle>Error</AlertTitle>
            {alertMessage}
          </Alert>
        ) : null}
      </Box>
    </Box>
  );
}

export default Login;
