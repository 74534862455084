import { Box, TextField, Typography } from "@mui/material";
import { makeRequest } from "../assets/functions_helper";
import { useState } from "react";
import { ButtonAnimated } from "../components/resources";
import SendIcon from "@mui/icons-material/Send";
import SnackBar from "../components/snackBar";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [emailSended, setEmailSended] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const handleSendEmailToForgotPassword = () => {
    makeRequest(
      "POST",
      `users/forgot_password?email=${email}`,
      { email },
      "application/json",
      null,
      null,
      null,
      null,
      () => {
        console.log("Error");
      }
    )
      .then((response) => {
        setSnackBarOpen(true);
        setSnackBarMessage(
          "Correo Enviado, Por favor Revisa tu Correo Electronico"
        );
        setEmailSended(true);
        return;
      })
      .catch((error) => {
        setSnackBarOpen(true);
        setSnackBarMessage("Error al Enviar el Correo Electronico");
        return;
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          gap: "20px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
          }}
        >
          {!emailSended
            ? "Escribe Tu Correo Electronico Asociado a tu Cuenta"
            : "Correo Enviado, Por favor Revisa tu Correo Electronico"}
        </Typography>
        {!emailSended && (
          <>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              sx={{
                width: "300px",
              }}
            />
            <ButtonAnimated
              onClick={handleSendEmailToForgotPassword}
              variant="contained"
              sx={{
                backgroundColor: "#4EACDA",
                color: "white",
                "&:hover": {
                  backgroundColor: "#4EACDA",
                },
                width: "200px",
              }}
              startIcon={<SendIcon />}
            >
              Enviar
            </ButtonAnimated>
          </>
        )}
      </Box>
      {snackBarOpen ? (
        <SnackBar
          openSnackBar={snackBarOpen}
          setOpenSnackBar={setSnackBarOpen}
          messageSnackBar={snackBarMessage}
        />
      ) : null}
    </>
  );
}

export default ForgotPassword;
