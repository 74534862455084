import React from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { IconButtonAnimated } from "./resources";
import { Box, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import CustomSelect from "./selectFilterStyled";
import EditIcon from "../assets/images/edit_icon.png";
import DeteleIcon from "../assets/images/icon_trash.png";
import CustomPagination from "./customPagination";
import { AddButtonStyled, AddButtonStyledText } from "./buttons";

function ListData({
  title,
  data,
  add = false,
  addMessage = "",
  edit = false,
  editMessage = "",
  editFunction = null,
  pages = 0,
  setPage = null,
  page = 1,
  changePage = null,
  create = false,
  createMessage = "",
  createFunction = null,
  pagination = true,
  nameColumn1 = "Columna 1",
  nameColumn2 = "Columna 2",
  nameColumn3 = "Columna 3",
  filterSelect = false,
  filterSelectFunction = () => {},
  valuesFilter = [],
  valueFilter = "",
  qr_option = false,
  deleted = false,
  deletedFunction = () => {},
  deteletedMessage = "",
}) {
  const handleChange = (event, value) => {
    setPage(value);
    changePage(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        width: "100%",
        marginTop: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "90%",
          '@media (max-width:950px)': {
            flexDirection: "column",
            gap: "20px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: 28,
            fontWeight: "400",
            wordWrap: "break-word",
            width: "70%",
            '@media (max-width:950px)': {
              width: "100%",
              textAlign: "center",
            },
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "30%",
            '@media (max-width:950px)': {
              width: "100%",
              justifyContent: "center",
            },
          }}
        >
          {filterSelect && (
            <CustomSelect
              value={valueFilter}
              onChange={filterSelectFunction}
              valuesFilter={valuesFilter}
            ></CustomSelect>
          )}
          {create ? (
            <AddButtonStyled onClick={createFunction} sx={{}}>
              <AddButtonStyledText>{createMessage}</AddButtonStyledText>
            </AddButtonStyled>
          ) : null}
        </Box>
      </Box>

      <TableContainer
        component={Paper}
        className="tableContainer"
        sx={{
          margin: "auto",
          background: "#040421",
        }}
      >
        <Table aria-label="simple table">
          <TableHead
            className="tableHeader"
            sx={{
              background: "rgba(104, 104, 133, 0.05)", // Mueve la opacidad aquí
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  opacity: 0.5,
                  color: "white",
                  fontSize: 16,
                  fontFamily: "Arial",
                  fontWeight: "400",
                  wordWrap: "break-word",
                  borderBottom: "0.50px rgba(255, 255, 255, 0.17) solid",
                  borderTop: "0.50px rgba(255, 255, 255, 0.17) solid",
                }}
              >
                {nameColumn1}
              </TableCell>
              <TableCell
                sx={{
                  opacity: 0.5,
                  color: "white",
                  fontSize: 16,
                  fontFamily: "Arial",
                  fontWeight: "400",
                  wordWrap: "break-word",
                  borderBottom: "0.50px rgba(255, 255, 255, 0.17) solid",
                  borderTop: "0.50px rgba(255, 255, 255, 0.17) solid",
                }}
              >
                {nameColumn2}
              </TableCell>
              <TableCell
                sx={{
                  opacity: 0.5,
                  color: "white",
                  fontSize: 16,
                  fontFamily: "Arial",
                  fontWeight: "400",
                  wordWrap: "break-word",
                  borderBottom: "0.50px rgba(255, 255, 255, 0.17) solid",
                  borderTop: "0.50px rgba(255, 255, 255, 0.17) solid",
                }}
              >
                {nameColumn3}
              </TableCell>
              {/* Agrega más <TableCell> aquí si necesitas más columnas */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3}>No hay datos</TableCell>
              </TableRow>
            ) : null}

            {data.map((item, index) => (
              <TableRow
                key={index}
                className="tableRow"
                sx={{
                  background:
                    (index + 1) % 2 === 0
                      ? "#0F0F2C"
                      : "rgba(104, 104, 133, 0.01)", // Mueve la opacidad aquí
                  height: 80,
                }}
              >
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: 16,
                    fontFamily: "Arial",
                    fontWeight: "400",
                    wordWrap: "break-word",
                    borderBottom: "0.50px rgba(255, 255, 255, 0.17) solid",
                  }}
                >
                  {item.title}
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: 16,
                    fontFamily: "Arial",
                    fontWeight: "400",
                    wordWrap: "break-word",
                    borderBottom: "0.50px rgba(255, 255, 255, 0.17) solid",
                  }}
                >
                  {item.title2}
                </TableCell>
                <TableCell
                  sx={{
                    color: "white",
                    fontSize: 16,
                    fontFamily: "Arial",
                    fontWeight: "400",
                    wordWrap: "break-word",
                    borderBottom: "0.50px rgba(255, 255, 255, 0.17) solid",
                  }}
                >
                  {add ? (
                    <IconButton
                      edge="end"
                      aria-label={addMessage}
                      sx={{ margin: "10px" }}
                    >
                      <AddIcon />
                    </IconButton>
                  ) : null}

                  {edit ? (
                    <IconButtonAnimated
                      edge="end"
                      aria-label={editMessage}
                      sx={{ margin: "10px" }}
                      onClick={() => editFunction(item.id, item.title, item.public_id, item)}
                    >
                      {qr_option ? (
                        <QrCode2Icon />
                      ) : (
                        <img
                          src={EditIcon}
                          alt="Notification"
                          style={{ width: "30px", height: "30px" }}
                        />
                      )}
                    </IconButtonAnimated>
                  ) : null}

                  {deleted ? (
                    <IconButtonAnimated
                      edge="end"
                      aria-label={deteletedMessage}
                      sx={{ margin: "10px" }}
                      onClick={() => deletedFunction(item.id, item.title)}
                    >
                      <img
                        src={DeteleIcon}
                        alt="Notification"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </IconButtonAnimated>
                  ) : null}
                </TableCell>
                {/* Agrega más <TableCell> aquí para mostrar más datos de 'item' */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#070724",
            width: "100%",
            height: 100,
          }}
        >
          <CustomPagination page={page} count={pages} onChange={handleChange} />
        </Box>
      )}
    </Box>
  );
}

export default ListData;
