import React, { useState, useEffect } from "react";
import { makeRequest } from "../../../assets/functions_helper";
import { useNavigate } from "react-router-dom";
import { Box, Typography, MenuItem } from "@mui/material";
import moment from "moment-timezone";
import { CustomSelect } from "../../../components/textFields";
import { styled } from "@mui/material/styles";

const CustomBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: 20, // Bordes redondeados
  background: "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)", // Gradiente de fondo
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Sombra para dar profundidad
  transition: "background 0.3s ease, transform 0.3s ease", // Transiciones suaves
  "&:hover": {
    cursor: "pointer",
    background: "linear-gradient(92deg, #4a3c7c 0%, #7c6db5 100%)", // Gradiente en hover
    transform: "scale(1.03)", // Efecto de escalado en hover
  },
}));

function ListCalls() {
  const [calls, setCalls] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState("");
  const navigate = useNavigate();
  const [ws, setWs] = useState(null); // Estado para la conexión WebSocket

  useEffect(() => {
    // Cargar la lista de restaurantes
    makeRequest(
      "GET",
      "restaurant_admin/list_my_restaurants?page=1&size=999&order_by=id&order=desc",
      {},
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        navigate("/login");
      }
    ).then((response) => {
      if (response && response.restaurants) {
        setRestaurants(response.restaurants);
        // Opcionalmente, seleccionar un restaurante por defecto
        // setSelectedRestaurantId(response.restaurants[0]?.id);
      }
    });
  }, [navigate]);

  useEffect(() => {
    if (selectedRestaurantId) {
      fetchData(selectedRestaurantId);
      // Solo crear una nueva conexión WebSocket si ws no está definido o si ya se cerró
      if (!ws || ws.readyState === WebSocket.CLOSED) {
        if (ws) {
          ws.close();
        }
        const newWs = new WebSocket(
          `wss://eatery-z81ocn81.b4a.run/ws/table_notification/${selectedRestaurantId}`
        );

        newWs.onopen = () => {
          console.log("WebSocket connected");
        };
        newWs.onmessage = (event) => {
          console.log("Mensaje recibido del WebSocket:", event.data); // Para depuración
          const newCall = JSON.parse(event.data);
          newCall.isNew = true;
          const timezone = moment.tz.guess();
          newCall.created_at_local = moment
            .utc(newCall.created_at)
            .tz(timezone)
            .format("YYYY-MM-DD HH:mm:ss");
          setCalls((prevCalls) => [newCall, ...prevCalls]);
        };
        newWs.onclose = () => {
          console.log("WebSocket disconnected");
        };
        // Guardar la nueva conexión WebSocket en el estado
        setWs(newWs);
      }
    }
  }, [selectedRestaurantId, ws]); // Remover ws de las dependencias

  const fetchData = async (restaurantId) => {
    // Crear las fechas y obtenerlas en UTC
    const startTime = new Date();
    const endTime = new Date(startTime.getTime() - 60 * 60 * 1000);

    // Convertir las fechas a UTC en el formato correcto
    const startTimeStr = startTime.toISOString();
    const endTimeStr = endTime.toISOString();

    console.log("Start Time (UTC):", startTimeStr);
    console.log("End Time (UTC):", endTimeStr);

    // Hacer la solicitud con las fechas en UTC
    const response = await makeRequest(
      "GET",
      `restaurant_admin/calls/${restaurantId}?start_time=${encodeURIComponent(
        endTimeStr
      )}&end_time=${encodeURIComponent(startTimeStr)}`,
      null,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        console.log("Redirect to login");
      }
    );

    // Convertir las fechas 'created_at' en el response
    const timezone = moment.tz.guess();
    const updatedCalls = response.map((call) => ({
      ...call,
      created_at_local: moment
        .utc(call.created_at)
        .tz(timezone)
        .format("YYYY-MM-DD HH:mm:ss"),
    }));
    console.log("Calls:", updatedCalls);

    setCalls(updatedCalls);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2, margin: "20px" }}
    >
      <CustomSelect
        value={selectedRestaurantId}
        onChange={(e) => setSelectedRestaurantId(e.target.value)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{ width: "300px" }}
      >
        <MenuItem value="">
          <em>Seleccione un restaurante</em>
        </MenuItem>
        {restaurants.map((restaurant) => (
          <MenuItem key={restaurant.id} value={restaurant.id}>
            {restaurant.name}
          </MenuItem>
        ))}
      </CustomSelect>
      {calls.length > 0 ? (
        calls.map((call, index) => (
          <CustomBox
            key={index}
            onClick={() => {
              setCalls((prevCalls) =>
                prevCalls.map((prevCall, i) =>
                  i === index ? { ...prevCall, isNew: false } : prevCall
                )
              );
            }}
          >
            <Typography variant="body1" sx={{ color: "white" }}>
              Creado El: {call.created_at_local}
            </Typography>
            <Typography variant="body1" sx={{ color: "white" }}>
              Mesa: {call.restaurant_table_name}
            </Typography>
          </CustomBox>
        ))
      ) : (
        <Typography>No calls found.</Typography>
      )}
    </Box>
  );
}

export default ListCalls;
