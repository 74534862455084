import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TawkToWidget = () => {
  const location = useLocation();

  useEffect(() => {
    // Function to add Tawk.to script
    const addTawkToScript = () => {
      var s1 = document.createElement('script');
      s1.async = true;
      s1.src = 'https://embed.tawk.to/668aeb86e1e4f70f24ee7f3d/1i27ag195';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s1.id = 'tawk-script';
      document.body.appendChild(s1);
    };

    // Function to remove Tawk.to script
    const removeTawkToScript = () => {
      const tawkScript = document.getElementById('tawk-script');
      if (tawkScript) {
        tawkScript.remove();
      }
      if (window.Tawk_API && window.Tawk_API.hideWidget) {
        window.Tawk_API.hideWidget();
      }
    };

    // Check if the script should be added or removed based on the current URL
    if (location.pathname !== '/clientes') {
      addTawkToScript();
    } else {
      removeTawkToScript();
    }

    // Clean up function to remove the script when the component unmounts or URL changes
    return () => {
      removeTawkToScript();
    };
  }, [location.pathname]);

  return null;
};

export default TawkToWidget;