import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { makeRequest } from "../../../../assets/functions_helper";
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../../components/customDialog";
import SortableList, { SortableItem } from "react-easy-sort";
import { CloseButton } from "../../../../components/buttons";
import { useNavigate } from "react-router-dom";

function ProductSelectionDialog({
  open,
  handleClose,
  restaurantId,
  onAddProduct
}) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = () => {
      setLoading(true);
      makeRequest(
        "GET",
        `menu_manager/list_restaurant_products?restaurant_id=${restaurantId}&page=1&size=10`,
        {},
        "application/json",
        localStorage.getItem("token"),
        localStorage.getItem("tokenType"),
        null,
        null,
        () => {
          navigate("/login");
        }
      )
        .then((response) => {
          setProducts(response.products);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error al obtener los productos:", error);
          setLoading(false);
        });
    };

    if (open) {
      fetchProducts();
    }
  }, [open, restaurantId, navigate]);

  const handleProductClick = (product) => {
    onAddProduct(product);
    handleClose();
  };

  // Maneja el final del arrastre, aunque no hagas nada
  const onSortEnd = (oldIndex, newIndex) => {
    // Aquí podrías implementar lógica si quisieras hacer algo al finalizar el arrastre.
  };

  return (
    <CustomDialog open={open} onClose={handleClose}>
      <CustomDialogTitle>Selecciona un Producto para Agregar</CustomDialogTitle>
      <CustomDialogContent>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <SortableList
            className="list"
            onSortEnd={onSortEnd} // Agrega aquí la función
          >
            {products.map((product, index) => (
              <SortableItem key={index}>
                <Box
                  className="item"
                  onClick={() => handleProductClick(product)}
                  style={{
                    backgroundImage: `url(${
                      product.image_url || "https://via.placeholder.com/150"
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    color: "white",
                    width: "150px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        transition: "background 0.3s ease",
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        textAlign: "center",
                        position: "relative",
                        zIndex: 1,
                        padding: "5px",
                        borderRadius: "4px",
                      }}
                    >
                      {product.name} <br />
                      {product.price} $
                    </Typography>
                  </Box>
                </Box>
              </SortableItem>
            ))}
          </SortableList>
        )}
      </CustomDialogContent>
      <CustomDialogActions>
        <CloseButton onClick={handleClose}>Cerrar</CloseButton>
      </CustomDialogActions>
    </CustomDialog>
  );
}

export default ProductSelectionDialog;
