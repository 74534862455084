import React, { useState, useEffect } from "react";
import { errorMapping, makeRequest } from "../../../assets/functions_helper";
import ListData from "../../../components/listData";
import {
  Box,
  CircularProgress,
  DialogContentText,
} from "@mui/material";
import InsertsUsersToRestaurant from "./inserts_users_to_restaurant";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../../components/snackBar";
import { CustomDialog, CustomDialogActions, CustomDialogContent, CustomDialogTitle } from "../../../components/customDialog";
import { CloseButton, SendButton } from "../../../components/buttons";

function ListUsersRestaurants({
  idRestaurant,
  role = [2],
  title,
  titleCreate,
  titleContent,
  nameColumn1 = "Columna 1",
  nameColumn2 = "Columna 2",
  nameColumn3 = "Columna 3",
  nameOfRestaurant = "",
  waiters = false,
}) {
  const [usersOfRestaurant, setUsersOfRestaurant] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false); // Variable de estado para saber si la petición ha cargado
  const [open, setOpen] = useState(false); // Variable de estado para abrir y cerrar el dialogo
  const [updateTrigger, setUpdateTrigger] = useState(0); // Inicializa el contador

  const [userDetail, setUserDetail] = useState({
    name: "",
    last_name: "",
    email: "",
    lastLogin: "",
    is_active: true,
    roles: [2],
    password: "",
  });

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  const [snackBarMessage, setSnackBarMessage] = React.useState("");

  const navigate = useNavigate();

  useEffect(() => {
    let urlBase;
    // Transforma cada ID de rol en una cadena de consulta

    if (localStorage.getItem("rol") === "2") {
      const rolesQueryString = role.map((id) => `role_ids=${id}`).join("&");
      urlBase = `restaurant_admin/users/${idRestaurant}?${rolesQueryString}`;
    } else {
      const rolesQueryString = role.map((id) => `role_ids=${id}`).join("&");
      urlBase = `restaurants/${idRestaurant}/users?${rolesQueryString}`;
    }

    const url = `${urlBase}`;

    makeRequest(
      "GET",
      url, // Asume que este es el endpoint correcto para filtrar usuarios por rol
      {},
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        navigate("/login");
      }
    ).then((response) => {
      let updatedUsers = [];
      // Actualiza el estado de los usuarios del restaurante
      if (localStorage.getItem("rol") === "2") {
        updatedUsers = response.map((user) => ({
          ...user,
          title: user.name,
          title2: `${user.email}`,
        }));
      } else {
        updatedUsers = response.users.map((user) => ({
          ...user,
          title: user.name,
          title2: `${user.email}`,
        }));
      }

      setUsersOfRestaurant(updatedUsers);
      setIsLoaded(true);
    });
  }, [idRestaurant, updateTrigger, navigate, role]);

  const handleClickCreateUser = () => {
    let url;
    // Asumiendo que `rol` es el estado o prop que contiene el valor del rol actual
    if (localStorage.getItem("rol") === "2") {
      // Utiliza el endpoint específico para el rol 2
      url = `restaurant_admin/create_and_assign_user/?restaurant_id=${idRestaurant}&role_id=${userDetail.roles[0]}`;
    } else {
      // Utiliza el otro endpoint para cualquier otro rol
      url = `users/create_and_assign_user/?restaurant_id=${idRestaurant}&role_id=${userDetail.roles[0]}`;
    }

    console.log(userDetail);
    makeRequest(
      "POST",
      url, // Asume que este es el endpoint correcto para filtrar usuarios por rol
      {
        name: userDetail.name,
        email: userDetail.email,
        last_name: userDetail.last_name,
        password: userDetail.password,
        roles: userDetail.roles,
      },
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        navigate("/login");
      }
    )
      .then((response) => {
        console.log(response);
        setOpen(false);
        setUpdateTrigger((prev) => prev + 1); // Incrementa el contador para disparar la actualización
      })
      .catch((error) => {
        console.log(error);
        if (error.length > 0) {
          for (const code in errorMapping) {
            if (error.includes(code)) {
              setSnackBarMessage(errorMapping[code]);
              setSnackBarOpen(true);
              return; // Salir de la función si se encuentra un código de error
            }
          }
        }
      });
  };

  return (
    <>
      {isLoaded ? (
        <>
          <ListData
            title={title}
            data={usersOfRestaurant}
            setData={setUsersOfRestaurant}
            edit={false}
            editMessage="Editar Usuario"
            editFunction={() => {}}
            page={null}
            setPage={null}
            changePage={() => {}}
            pages={null}
            total={null}
            currentPage={null}
            create={true}
            createMessage={titleCreate}
            createFunction={() => {
              setOpen(true);
            }}
            pagination={false}
            nameColumn1={nameColumn1}
            nameColumn2={nameColumn2}
            nameColumn3={nameColumn3}
          />

          <CustomDialog
            open={open}
            onClose={() => {
              setUserDetail({
                name: "",
                last_name: "",
                email: "",
                lastLogin: "",
                is_active: true,
                roles: [2],
                password: "",
              });
              setOpen(!open);
            }}
          >
            <CustomDialogTitle>{titleCreate}</CustomDialogTitle>
            <CustomDialogContent>
              <DialogContentText>{titleContent}</DialogContentText>

              <InsertsUsersToRestaurant
                user={userDetail}
                setUser={setUserDetail}
                waiters={waiters}
              />
            </CustomDialogContent>

            <CustomDialogActions>
              <CloseButton
                onClick={() => {
                  setUserDetail({
                    name: "",
                    last_name: "",
                    email: "",
                    lastLogin: "",
                    is_active: true,
                    roles: [2],
                    password: "",
                  });
                  setOpen(!open);
                }}
              >
                Cerrar
              </CloseButton>
              <SendButton onClick={handleClickCreateUser}>Enviar</SendButton>
            </CustomDialogActions>
          </CustomDialog>

          {snackBarOpen ? (
            <SnackBar
              openSnackBar={snackBarOpen}
              setOpenSnackBar={setSnackBarOpen}
              messageSnackBar={snackBarMessage}
            />
          ) : null}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default ListUsersRestaurants;
