import { Button, styled } from "@mui/material";

export const AddButtonStyled = styled("div")(({ theme }) => ({
  width: 'auto',
  height: 42,
  padding: '0 20px',
  background: "#6DBBE6",
  boxShadow: "0px 0px 6px rgba(255, 255, 255, 0.05) inset",
  borderRadius: 30,
  border: "0.50px rgba(255, 255, 255, 0.70) solid",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 4,
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#fff',
  transition: 'background 0.3s ease, transform 0.3s ease',
  '&:hover': {
    cursor: "pointer",
    background: "#5A9FD4",
    transform: 'scale(1.05)',
  },
  '&:active': {
    transform: 'scale(0.95)',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: '0 0 0 3px rgba(255, 255, 255, 0.5)',
  },
}));

export const AddButtonStyledText = styled("div")(({ theme }) => ({
  color: "white",
  fontSize: 12,
  fontFamily: "Arial",
  fontWeight: 400,
  wordWrap: "break-word",
}));

export const SendButton = styled(Button)(({ theme }) => ({
  color: 'white',
  fontSize: 15,
  fontFamily: 'Arial',
  fontWeight: 400,
  padding: '10px 20px',
  borderRadius: 10,
  textTransform: 'none',
  background: 'linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)', // Gradiente personalizado
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
  transition: 'background 0.3s ease, transform 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(92deg, #4a3c7c 0%, #7c6db5 100%)', // Gradiente en hover
    transform: 'scale(1.05)',
  },
  '&:active': {
    background: 'linear-gradient(92deg, #3a2b5e 0%, #6a5a9b 100%)', // Gradiente en active
    transform: 'scale(0.95)',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: '0 0 0 3px rgba(121, 109, 184, 0.5)',
  },
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  color: 'white',
  fontSize: 15,
  fontFamily: 'Arial',
  fontWeight: 400,
  padding: '10px 20px',
  borderRadius: 10,
  textTransform: 'none',
  backgroundColor: '#FF4C4C', // Rojo para indicar cerrar
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
  transition: 'background 0.3s ease, transform 0.3s ease',
  '&:hover': {
    backgroundColor: '#e03d3d',
    transform: 'scale(1.05)',
  },
  '&:active': {
    backgroundColor: '#c72c2c',
    transform: 'scale(0.95)',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: '0 0 0 3px rgba(255, 255, 255, 0.5)',
  },
}));