import React, { useState, useEffect, useRef } from 'react';
import { errorMapping, makeRequest } from '../../../assets/functions_helper';
import ListData from '../../../components/listData';
import {
  Box,
  CircularProgress,
  DialogContentText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import SnackBar from '../../../components/snackBar';
import domtoimage from 'dom-to-image';
import { CustomDialog, CustomDialogActions, CustomDialogContent, CustomDialogTitle } from '../../../components/customDialog';
import { CustomTextField } from '../../../components/textFields';
import { CloseButton, SendButton } from '../../../components/buttons';

function ListTablesRestaurant({
  idTable,
  title,
  titleCreate,
  titleContent,
  nameColumn1,
  nameColumn3,
  nameOfRestaurant,
}) {
  const [tablesRestaurant, setTablesRestaurant] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false); // Variable de estado para saber si la petición ha cargado
  const [open, setOpen] = useState(false); // Variable de estado para abrir y cerrar el dialogo
  const [updateTrigger, setUpdateTrigger] = useState(0); // Inicializa el contador
  const [tableDetail, setTableDetail] = useState({
    name: '',
    isActive: true,
  });

  const [url, setUrl] = useState('');
  const [qr, setQr] = useState(false);
  const qrRef = useRef();
  const navigate = useNavigate();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [tableToDelete, setTableToDelete] = useState(null);
  const [openTableDelete, setOpenTableDelete] = useState(false);

  useEffect(() => {
    makeRequest(
      'GET',
      `restaurants/${idTable}/tables`,
      {},
      'application/json',
      localStorage.getItem('token'),
      localStorage.getItem('tokenType'),
      null,
      null,
      () => {
        navigate('/login');
      }
    ).then((response) => {
      const updatedTables = response.map((table) => ({
        ...table,
        title: table.name,
        title2: `${table.is_active}`,
      }));

      setTablesRestaurant(updatedTables);
      setIsLoaded(true);
    });
  }, [idTable, updateTrigger, navigate]);

  const handleClickCreateUser = () => {
    makeRequest(
      'POST',
      `restaurants/create_table`,
      {
        restaurant_id: idTable,
        name: tableDetail.name,
      },
      'application/json',
      localStorage.getItem('token'),
      localStorage.getItem('tokenType'),
      null,
      null,
      () => {
        navigate('/login');
      }
    )
      .then((response) => {
        console.log(response);
        setOpen(false);
        setUpdateTrigger((prev) => prev + 1);
      })
      .catch((err) => {
        for (const error in errorMapping) {
          console.log(err['error_code']);
          if (error.includes(err['error_code'])) {
            setSnackBarMessage(errorMapping[err['error_code']]);
            setSnackBarOpen(true);
            return; // Salir de la función si se encuentra un código de error
          }
        }
      });
  };

  const handleDownloadQR = () => {
    domtoimage
      .toPng(qrRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `qr-code-mesa-${idTable}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error('Error al descargar el QR:', error);
      });
  };

  const deleteTable = (id) => {
    makeRequest(
      'DELETE',
      `restaurants/delete_table/${id}`,
      {},
      'application/json',
      localStorage.getItem('token'),
      localStorage.getItem('tokenType'),
      null,
      null,
      () => {
        navigate('/login');
      }
    )
      .then((response) => {
        setSnackBarMessage("Mesa Elimanada");
        setSnackBarOpen(true);
        setUpdateTrigger((prev) => prev + 1);
        setOpenTableDelete(!openTableDelete)
        return; // Salir de la función si se encuentra un código de error
      })
      .catch((err) => {
        for (const error in errorMapping) {
          console.log(err['error_code']);
          if (error.includes(err['error_code'])) {
            setSnackBarMessage(errorMapping[err['error_code']]);
            setSnackBarOpen(true);
            return; // Salir de la función si se encuentra un código de error
          }
        }
      });
  }

  return (
    <>
      {isLoaded ? (
        <>
          <ListData
            title={title}
            data={tablesRestaurant}
            setData={setTablesRestaurant}
            edit={true}
            editMessage="Editar Usuario"
            editFunction={(value, title, codeId) => {
              setUrl(`https://eateryco.com/clientes/${codeId}`);
              setQr(!qr);
            }}
            page={null}
            setPage={null}
            changePage={() => {}}
            pages={null}
            total={null}
            currentPage={null}
            create={true}
            createMessage="Crear"
            createFunction={() => {
              setOpen(true);
            }}
            pagination={false}
            nameColumn1={'Mesas'}
            nameColumn3={'Acciones'}
            nameOfRestaurant={nameOfRestaurant}
            qr_option={true}
            deleted={true}
            deletedFunction = {(id, title) => {
              setOpenTableDelete(true);
              setTableToDelete(id);
            }}
            deteletedMessage = "Eliminar Mesa"
          />

          <CustomDialog open={open} onClose={() => setOpen(!open)}>
            <CustomDialogTitle>{titleCreate}</CustomDialogTitle>
            <CustomDialogContent>
              <DialogContentText>{titleContent}</DialogContentText>
              <CustomTextField
                autoFocus
                required
                margin="dense"
                id="name"
                name="name"
                label="Nombre"
                type="text"
                fullWidth
                value={tableDetail.name}
                onChange={(e) =>
                  setTableDetail({ ...tableDetail, name: e.target.value })
                }
              />
            </CustomDialogContent>

            <CustomDialogActions>
              <CloseButton onClick={() => setOpen(!open)}>Cerrar</CloseButton>
              <SendButton onClick={handleClickCreateUser}>Enviar</SendButton>
            </CustomDialogActions>
          </CustomDialog>

          <CustomDialog open={qr} onClose={() => setOpen(!qr)}>
            <CustomDialogTitle>QRCode</CustomDialogTitle>
            <CustomDialogContent>
              <DialogContentText>
                <div ref={qrRef}>
                  <QRCode value={url} />
                </div>
              </DialogContentText>
            </CustomDialogContent>

            <CustomDialogActions>
              <SendButton onClick={handleDownloadQR}>Descargar QR</SendButton>
              <CloseButton onClick={() => setQr(!qr)}>Cerrar</CloseButton>
            </CustomDialogActions>
          </CustomDialog>

          <CustomDialog open={openTableDelete} onClose={() => setOpenTableDelete(!openTableDelete)}>
            <CustomDialogTitle>Eliminar Mesa</CustomDialogTitle>
            <CustomDialogContent>
              <DialogContentText>
                ¿Esta seguro que desea eliminar la mesa {tableToDelete}?
              </DialogContentText>
            </CustomDialogContent>

            <CustomDialogActions>
              <CloseButton onClick={() => deleteTable(tableToDelete)}>Eliminar</CloseButton>
              <CloseButton onClick={() => setOpenTableDelete(!openTableDelete)}>Cerrar</CloseButton>
            </CustomDialogActions>
          </CustomDialog>

          {snackBarOpen ? (
            <SnackBar
              openSnackBar={snackBarOpen}
              setOpenSnackBar={setSnackBarOpen}
              messageSnackBar={snackBarMessage}
            />
          ) : null}
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default ListTablesRestaurant;
