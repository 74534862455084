import React from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';

function QRCodeGenerator() {
    let { tableNumber } = useParams();

  return <QRCode value={`https://eateryco.com/clientes/${tableNumber}`} />;
}

export default QRCodeGenerator;
